import {convertFromDate, convertToDate} from "../shared/utils/DateUtils";
import moment from "moment";
import JournalType from "./JournalType";
export default class Journal {
    id?: number;
    name?: string;
    scheduledHours?: number;
    loggedHours?: number;
    remainingHours?: number;
    validFrom?: string;
    validFromToShowOnForm?: Date;
    validTo?: string;
    validToToShowOnForm?: Date;
    journalType?: JournalType;
    active?: boolean;
    isActive?: boolean;

    constructor(id?: number,name?: string, scheduledHours?: number, validFrom?: string, validTo?: string, journalType?: JournalType, validFromToShowOnForm?: Date, validToToShowOnForm?: Date, active?: boolean,loggedHours?: number,remainingHours?: number) {
        this.id = id ?? undefined;
        this.name = name ?? '';
        this.scheduledHours = scheduledHours ?? 0;
        this.validFrom = validFrom ?? '';
        this.validTo = validTo ?? '';
        this.journalType = journalType ?? undefined;
        this.validFromToShowOnForm = validFromToShowOnForm ?? undefined;
        this.validToToShowOnForm = validToToShowOnForm ?? undefined;
        this.active = active;
        this.remainingHours = remainingHours ?? undefined;
        this.loggedHours = loggedHours ?? undefined;
    }
}


export function makeJournal(journal: Journal | undefined) {
    if (journal === undefined) {
        return new Journal();
    }
    journal.id = journal.id;
    journal.validFromToShowOnForm = convertToDate(journal.validFrom);
    journal.validToToShowOnForm = convertToDate(journal.validTo);
    journal.isActive = journal.active;
    
    if(journal.loggedHours === null && journal.remainingHours === null){
        journal.loggedHours = 0;
        journal.remainingHours = journal.scheduledHours;
    }

    return journal;
}

export function makeJournalForInsert(journal: Journal | undefined) {
    if (journal === undefined) {
        return new Journal();
    }
    
    journal.validFrom = convertFromDate(journal.validFromToShowOnForm);
    journal.validTo = convertFromDate(journal.validToToShowOnForm);
    
    return journal;
}

export function updateJournal(journal: Journal | undefined) {
    let journalNew = new Journal();
    journalNew.id = journal?.id;
    journalNew.name = journal?.name;
    journalNew.scheduledHours = journal?.scheduledHours;
    journalNew.validFrom = convertFromDate(journal?.validFromToShowOnForm);
    journalNew.validTo = convertFromDate(journal?.validToToShowOnForm);
    journalNew.validFromToShowOnForm = journal?.validFromToShowOnForm;
    journalNew.validToToShowOnForm = journal?.validToToShowOnForm;
    journalNew.journalType = journal?.journalType;
    
    return journalNew;
}

