import React, {useEffect, useMemo, useRef, useState} from 'react';
import {DataTable, DataTableExpandedRows} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {InputNumber} from 'primereact/inputnumber';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {Calendar} from 'primereact/calendar';
import GenericButton, {EButtonColor, EButtonIcons, EButtonStyle} from '../button/GenericButton';
import {resetAllHolidays} from '../../../api/endpoints/CrudApiCustom';
import {classNames} from 'primereact/utils';
import {Divider} from "primereact/divider";
import {EColors} from "../../../../styles/themes/luminum-theme/Constants";
import _ from 'lodash';
import ColumnSelectionModal from "../column-selection-modal/ColumnSelectionModal";
import {Paginator} from "primereact/paginator";
import {Dropdown} from "primereact/dropdown";

type TieredMenuOptions = {
    label: string,
    icon: string,
    items?: TieredMenuOptions[],
    command?: () => void,
}

export type TableOptions = {
    options: {
        title: string,
        toFetch: Promise<any>[],
        hasHeader: boolean;
        isDataMutable: boolean;
        expansionTemplate: (params: any) => JSX.Element,
        columns: {
            headers: Array<string>,
            fieldNames: Array<string>,
            fieldTypes: Array<string>,
            searchByFieldNames: Array<string>,
            additionalFieldParams?: {
                fields: Array<{
                    name: string,
                    originalFields: Array<string>,
                    dataset: number,
                    id: string
                }>
            }
        },
        modalOptions: {
            modals: any,
            hideVariables: Array<boolean>,
            hideSetters: Array<React.Dispatch<React.SetStateAction<boolean>>>,
            hideFunctions: Array<() => void>,
            initialValues: {
                initialValues: any,
                setInitialValues: React.Dispatch<any>
            };
            newValue?: any
        },
        vacationDays?: {
            available?: number;
            remaining?: number;
        },
        columnSelection: {
            headers: Array<string>,
            fieldNames: Array<string>,
            hideVariables: boolean,
            hideSetters: React.Dispatch<React.SetStateAction<boolean>>,
            hideFunctions: () => void,
            initialValues: {
                initialValues: any,
                setInitialValues: React.Dispatch<any>
            };
        },
        filter?: string,
        pagination?: {
            hasPageable?: boolean,
            onChange: (page: number, size: number) => Promise<any>
        },
        excelExportModalOption?: {
            modals: any,
            hideVariables: Array<boolean>,
            hideSetters: Array<React.Dispatch<React.SetStateAction<boolean>>>,
            hideFunctions: Array<() => void>
        },
        timeSheet?: {
            isUkupno: boolean,
        },
    },
}


/**
 *
 * Generic CRUD Table
 * -
 * Options
 * -
 * @property {string}  title - title
 * @property {Promise<any>[]}  toFetch - array of api calls (1st call is for main table entity)
 * @property {Array<string[]>}  columns - array of column options
 * @property {string[]}  columns[].headers - array of header names
 * @property {string[]}  columns[].fieldNames - array of field names as api returns them
 * @property {string[]}  columns[].searchByFieldNames - array of field names to use for global search(search by...)
 * @property {string[]}  columns[].filterByFieldNames - array of field names to use for filtering by column(filter by...)
 * @property {{}}  columns[].additionalFieldParams - optional parameters from other datasets
 * @property {Array<{}>}  columns[].additionalFieldParams.fields - optional fields from other datasets
 * @property {string}  columns[].additionalFieldParams.fields.name - desired field name (example: employeeName - made from firstName and lastName)
 * @property {Array<string>}  columns[].additionalFieldParams.fields.originalFields - original field names (currently supporting max of 2, example: firstName, lastName)
 * @property {number}  columns[].additionalFieldParams.fields.dataset - number of fetched dataset from toFetch array (excluding 0)
 * @property {string}  columns[].additionalFieldParams.fields.id - name of id from foreign table(example: employeeId)
 * @property {Array<JSX.Element>} columns[].buttons - array of crud buttons inside columns (ex. read, update, delete)
 * @property {JSX.Element} button - currently one additional button for create(outside of table)
 * @property {boolean} isDataMutable - if is then template and form is executed if not then this fakes just normal list table
 *
 */
const GenericTable = (props: TableOptions) => {
    const [data, setData] = useState(Array<any>);
    const [filters, setFilters] = useState({} as any);
    const [globalFilterValue, setGlobalFilterValue] = useState('' as any);
    const [loading, setloading] = useState(true);
    const [tableData, setTableData] = useState(Array<any>);
    const [expandedData, setExpandedData] = useState<any>();
    const [selectedData, setSelectedData] = useState(Array<any>);
    const [rowData, setRowData] = useState<any>();
    const [dataFromChild, setDataFromChild] = useState({} as any);
    const [totalRecords, setTotalRecords] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowNumber, setRowNumber] = useState<number>(500);
    const [sumOfHours, setSumOfHours] = useState<number>(0);
    const ref = useRef({} as any);
    const columns = _.map(props.options.columns.fieldNames, (field, index) => ({
        field,
        header: props.options.columns.headers[index]
    }));
    const columnSelectionSelectedColumns = _.map(props.options.columnSelection ? props.options.columnSelection.fieldNames : undefined, (field, index) => ({
        field,
        header: props.options.columnSelection.headers[index]
    }));
    const [visibleColumns, setVisibleColumns] = useState(columnSelectionSelectedColumns);

    let _tableData;

    useEffect(() => {
        (async () => {
            if (props.options.pagination?.hasPageable) {
                props.options.pagination.onChange(pageNumber, rowNumber)
                    .then((dataFromBackend: any) => {
                        if (dataFromBackend && dataFromBackend.content) {
                            let ar: Array<Array<any>> = [];
                            ar.push(dataFromBackend.content);
                            setData(ar);
                            setTotalRecords(dataFromBackend.totalElements);
                            setPageNumber(dataFromBackend.pageable.pageNumber);
                        }
                    })
                    .finally(() => setloading(false))
            } else {
                Promise.all(props.options.toFetch)
                    .then(data => {
                        setData(data);
                        setTotalRecords(data.length);
                        setPageNumber(0);
                    })
                    .then(() => initFilters())
                    .finally(() => setloading(false))
            }
        })();
    }, []);

    useMemo(async () => {

        _tableData = data[0];
        if (props.options.columns.additionalFieldParams) {
            for (let i = 0; i < props.options.columns.additionalFieldParams!.fields.length; i++) {

                if (props.options.columns.additionalFieldParams !== undefined && _tableData !== undefined) {
                    let name: string = props.options.columns.additionalFieldParams!.fields[i].name
                    let dataset = props.options.columns.additionalFieldParams!.fields[i].dataset;
                    let id = props.options.columns.additionalFieldParams!.fields[i].id;
                    let originalFields = props.options.columns.additionalFieldParams!.fields[i].originalFields;

                    _tableData = _tableData.map((item: any) => (
                        {
                            ...item,
                            [name]:
                                data[dataset] ?
                                    (originalFields[1] ?
                                        data[dataset].filter((f: any) => f.id === item[id])[0] ?
                                            (data[dataset].filter((f: any) => f.id === item[id])[0][originalFields[0]] +
                                                ' ' +
                                                data[dataset].filter((f: any) => f.id === item[id])[0][originalFields[1]]) : ('') :
                                        data[dataset].filter((f: any) => f.id === item[id])[0] ?
                                            (data[dataset].filter((f: any) => f.id === item[id])[0][originalFields[0]]) : (''))
                                    :
                                    ('')
                        }
                    ))
                }
            }
        }
        setTableData(props?.options?.filter !== undefined ? _tableData?.filter((t: any) => (t.employeeLdap.description === props?.options?.filter || t.employeeLdap.description === props?.options?.filter)) : _tableData);
    }, [data]);

    /*
    TABLE REFRESH
    */
    function handleDataFromChild(dataToUpdate: any, action: string) {
        setDataFromChild({dataToUpdate, action});
        setExpandedData({});
    }

    // Refresh table data on row update
    useEffect(() => {
        //
        // When row data changes on Expansion template it finds
        // index of that row and replaces old data with new
        // dataFromChild retrieved from child in expansion component
        //
        // Every template file has handleDataFromChild setter which changes state
        // on list and list sends it to expansionTemplate
        // From there we take dataToUpdate and corresponding action(edit or delete)
        // and use it to update tableData
        //
        if (tableData && dataFromChild && dataFromChild.dataToUpdate) {

            let _tableData = tableData;
            let index = _.findIndex(_tableData, _.find(_tableData, {id: dataFromChild.dataToUpdate.id}) ? {id: dataFromChild.dataToUpdate.id} : {employeeNumber: dataFromChild.dataToUpdate.employeeNumber});
            if (dataFromChild.action === 'edit') {
                _tableData?.splice(index, 1, dataFromChild.dataToUpdate);
            } else if (dataFromChild.action === 'delete') {
                _tableData?.splice(index, 1);
            }
            setExpandedData({});
            setTableData(_.orderBy(_tableData, ['isActive'], ['desc']));
        }
    }, [dataFromChild])

    // Refresh table data on create
    useEffect(() => {
        //
        // When we create a new record from form we pass dataFromChildCreate
        // to list and list passes it to table via newValue property inside
        // table options. After that we insert new record into tableData
        //
        if (tableData && props.options.modalOptions.newValue) {
            let _tableData = tableData;
            Array.isArray(props.options.modalOptions.newValue) ? _tableData.unshift(...props.options.modalOptions.newValue) : _tableData.unshift(props.options.modalOptions.newValue);
            setExpandedData({});
            setTableData(_.orderBy(_tableData, ['isActive'], ['desc']));
        }
    }, [props.options.modalOptions.newValue])

    const onNewRequest = () => {
        props.options.modalOptions.initialValues.setInitialValues(undefined);
        // TODO: find a better way to handle this
        if (!props.options.modalOptions.hideVariables[0]) {
            props.options.modalOptions.hideSetters[0](true);
        }
    };

    const onExcelDownloadRequest = () => {
        if (props.options.excelExportModalOption) {
            if (!props.options.excelExportModalOption.hideVariables[0]) {
                props.options.excelExportModalOption.hideSetters[0](true);
            }
        }
    };

    const forHeaderSelection = () => {
        props.options.columnSelection?.initialValues.setInitialValues(columnSelectionSelectedColumns);

        if (!props.options.columnSelection?.hideVariables) {
            props.options.columnSelection?.hideSetters(true);
        }
    };

    const pickTableHeaderModal = () => {
        if (props.options.columnSelection && props.options.columnSelection.hideVariables) {
            return (
                <>
                    <ColumnSelectionModal visibility={props.options.columnSelection.hideVariables}
                                          onHide={props.options.columnSelection.hideFunctions} allColumns={columns}
                                          setVisibleColumns={setVisibleColumns} visibleColumns={visibleColumns}
                                          onColumnToggle={onColumnToggle}/>
                </>
            )
        }
    }

    const clearFilter = () => {

        initFilters();
    }

    const onColumnToggle = (event: any) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

        setVisibleColumns(orderedSelectedColumns);
        _tableData = tableData;
        setTableData(_tableData);
    };

    const renderHeader = () => {
        return (
            // FIXME: Fix this, put more control over buttons in general
            <>
                {
                    (props.options.title == "It is not mutable") ?
                    <>
                        {notMutableDataHeader}
                    </> :
                    (props.options.title == "Pregled blagdana") ?
                        <>
                            {holidayHeader}
                        </> :
                        (props.options.title === "Pregled zaposlenika") ?
                            <>
                                {employeeHeader}
                            </>
                            :
                            (props.options.title === "Pregled mojih zahtjeva odsustva") ?

                                <>
                                    {myAbsenceHeader}
                                </>
                                :
                                (props.options.title === "Pregled svih evidencija radnog vremena" ||
                                    props.options.title === "Pregled mojih evidencija radnog vremena") ?

                                    <>
                                        {timeSheetHeader}
                                    </>
                                    :
                                    <div style={{padding: '0'}}>
                                        {defaultHeader}
                                    </div>
                }
            </>
        )
    }

    useEffect(() => {
        if (tableData) {
            setSumOfHours(tableData.map(x => x.numberOfHours).reduce((acc, hours) => acc + hours, 0));
        } else {
            setSumOfHours(0);
        }
    }, tableData);

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;

        let _filters = {...filters};

        if (!_filters['global']) {
            _filters['global'] = {value: '', matchMode: FilterMatchMode.CONTAINS};
        }

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const employeeHeader =
        <div className="flex justify-content-between gap-3">
            <div style={{width: '100%'}}>
                {props.options.hasHeader !== undefined && props.options.hasHeader ?
                    <div style={{paddingTop: "1.2rem"}}>
                        <h3>&nbsp;&nbsp;&nbsp;{props.options.title}</h3>
                        <Divider style={{height: '1px', background: EColors.DIVIDER_BLUE}}/>
                    </div>
                    :
                    <>
                        {}
                    </>
                }
                <div className="flex fadeout" style={{margin: 0, padding: 0}}>
                    <div className="col-1">
                        <GenericButton label="Novi" icon={EButtonIcons.PLUS} onClick={onNewRequest}
                                       color={EButtonColor.MILK_BLUE}
                                       styled={EButtonStyle.TRANSPOSE}/>
                    </div>
                    <div className="col-3 col-offset-7">
                        <div className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange}
                                       placeholder="Pretraži"
                                       style={{border: "transparent", background: "transparent", boxShadow: "none"}}/>
                        </div>
                    </div>
                    <div className="col-1">
                        <GenericButton type="button" icon={EButtonIcons.FILTER} label="Clear"
                                       color={EButtonColor.MILK_BLUE} styled={EButtonStyle.TRANSPOSE}
                                       onClick={clearFilter}/>
                    </div>
                </div>
            </div>
        </div>

    const notMutableDataHeader =
        <div className="flex justify-content-between gap-3">
            <div style={{width: '100%'}}>
                <div className="flex justify-content-end" style={{margin: 0, padding: 0}}>
                    <div className="col-3">
                        <div className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange}
                                       placeholder="Pretraži"
                                       style={{border: "transparent", background: "transparent", boxShadow: "none"}}/>
                        </div>
                    </div>
                    <div className="col-1">
                        <GenericButton type="button" icon={EButtonIcons.FILTER} label="Clear"
                                       color={EButtonColor.MILK_BLUE} styled={EButtonStyle.TRANSPOSE}
                                       onClick={clearFilter}/>
                    </div>
                </div>
            </div>
        </div>
    const holidayHeader =
        <div className="flex justify-content-between gap-3">
            <div style={{width: '100%'}}>
                {props.options.hasHeader !== undefined && props.options.hasHeader ?
                    <div style={{paddingTop: "1.2rem"}}>
                        <h3>{props.options.title}</h3>
                        <Divider style={{height: '1px', background: EColors.DIVIDER_BLUE}}/>
                    </div>
                    :
                    <>
                        {}
                    </>
                }
                <div className="flex" style={{margin: 0, padding: 0}}>
                    <div className="col-1">
                        <GenericButton label="Novi" icon={EButtonIcons.PLUS} onClick={onNewRequest}
                                       color={EButtonColor.MILK_BLUE}
                                       styled={EButtonStyle.TRANSPOSE}/>
                    </div>
                    <div className="col-2 col-offset-5">
                        <GenericButton label="Resetiraj blagdane"
                                       onClick={() => resetAllHolidays().then(res => res.status === 200 ? setTableData(res.data.customHolidays) : undefined)}
                                       color={EButtonColor.MILK_BLUE}
                                       styled={EButtonStyle.TRANSPOSE}/>
                    </div>
                    <div className="col-3">
                        <div className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange}
                                       placeholder="Pretraži"
                                       style={{border: "transparent", background: "transparent", boxShadow: "none"}}/>
                        </div>
                    </div>
                    <div className="col-1">
                        <GenericButton type="button" icon={EButtonIcons.FILTER} label="Clear"
                                       color={EButtonColor.MILK_BLUE} styled={EButtonStyle.TRANSPOSE}
                                       onClick={clearFilter}/>
                    </div>
                </div>
            </div>
        </div>
    const timeSheetHeader =
        <div className="flex justify-content-between gap-2">
            <div style={{width: '100%'}}>
                {props.options.hasHeader !== undefined && props.options.hasHeader ?
                    <div style={{paddingTop: "1.2rem"}}>
                        <h3>{props.options.title}</h3>
                        <Divider style={{height: '1px', background: EColors.DIVIDER_BLUE}}/>
                    </div>
                    :
                    <>
                        {}
                    </>
                }
                <div className="flex" style={{margin: 0, padding: 0}}>
                    <div className="col-1">
                        <GenericButton label="Novi" icon={EButtonIcons.PLUS} onClick={onNewRequest}
                                       color={EButtonColor.MILK_BLUE}
                                       styled={EButtonStyle.TRANSPOSE}/>
                    </div>
                    <div className="col-1 col-offset-5">
                        <GenericButton label="Izvoz u Excel" id="excelExport"
                                       color={EButtonColor.DARK_BLUE}
                                       styled={EButtonStyle.TRANSPOSE}
                                       onClick={onExcelDownloadRequest}/>
                    </div>
                    <div className="col-2">
                        <div className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange}
                                       placeholder="Pretraži"
                                       style={{border: "transparent", background: "transparent", boxShadow: "none"}}/>
                        </div>
                    </div>
                    <div className="col-1">
                        <GenericButton type="button" icon={EButtonIcons.FILTER} label="Clear"
                                       color={EButtonColor.MILK_BLUE} styled={EButtonStyle.TRANSPOSE}
                                       onClick={clearFilter}/>
                    </div>
                    {tableData ?
                        <div className="col-2 flex justify-content-center align-items-center"
                             style={{textAlign: 'center', fontWeight: 'normal'}}>
                            <p>Suma prikazanih: {sumOfHours}h</p>
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    const myAbsenceHeader =
        <div className="flex justify-content-between gap-3">
            <div style={{width: '100%'}}>
                {props.options.hasHeader !== undefined && props.options.hasHeader ?
                    <div style={{paddingTop: "1.2rem"}}>
                        <h3>{props.options.title}</h3>
                        <Divider style={{height: '1px', background: EColors.DIVIDER_BLUE}}/>
                    </div>
                    :
                    <>
                        {}
                    </>
                }
                <div className="flex" style={{margin: 0, padding: 0}}>
                    <div className="col-1">
                        <GenericButton label="Novi" icon={EButtonIcons.PLUS} onClick={onNewRequest}
                                       color={EButtonColor.MILK_BLUE}
                                       styled={EButtonStyle.TRANSPOSE}/>
                    </div>
                    <div className="col-2 col-offset-5">
                        <InputText id="absenceRequestAll"
                                   readOnly={true} style={{border: "transparent", boxShadow: "none"}}
                                   value={'Broj dana godišnjeg: ' + props.options.vacationDays?.available}/>
                    </div>
                    <div className="col-2 col-offset-1">
                        <InputText id="absenceRequestRemaining"
                                   readOnly={true} style={{border: "transparent", boxShadow: "none"}}
                                   value={'Neiskorišteni dani: ' + props.options.vacationDays?.remaining}/>
                    </div>
                </div>
            </div>
        </div>

    const defaultHeader =
        <div className="flex justify-content-between gap-3">
            <div style={{width: '100%'}}>
                {props.options.hasHeader ?
                    <div style={{paddingTop: "1rem"}}>
                        <h3>{props.options.title}</h3>
                        <Divider style={{height: '1px', background: EColors.DIVIDER_BLUE}}/>
                    </div>
                    :
                    <>
                        {}
                    </>
                }
                <div className="flex" style={{margin: 0, padding: 0}}>
                    <div className="col-1">
                        <GenericButton label="Novi" icon={EButtonIcons.PLUS} onClick={onNewRequest}
                                       color={EButtonColor.MILK_BLUE}
                                       styled={EButtonStyle.TRANSPOSE}/>
                    </div>
                    <div className="col-3 col-offset-6">
                        <div className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange}
                                       placeholder="Pretraži"
                                       style={{border: "transparent", background: "transparent", boxShadow: "none"}}/>
                        </div>
                    </div>
                    <div className="col-1">
                        <GenericButton type="button" icon={EButtonIcons.FILTER} label="Clear"
                                       color={EButtonColor.MILK_BLUE} styled={EButtonStyle.TRANSPOSE}
                                       onClick={clearFilter}/>
                    </div>
                </div>
            </div>
        </div>

    const header = renderHeader();

    const initFilters = () => {
        let _filters = {...filters};

        props.options.columns.fieldNames.map((name: string, i: number) => {

            if (i === 0) _filters['global'] = {value: null, matchMode: FilterMatchMode.CONTAINS};

            _filters[name]
                = props.options.columns.fieldTypes[i] === 'string' ?
                {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]} :
                props.options.columns.fieldTypes[i] === 'date' ?
                    {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]} :
                    props.options.columns.fieldTypes[i] === 'numeric' ?
                        {
                            operator: FilterOperator.AND,
                            constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
                        } :
                        props.options.columns.fieldTypes[i] === 'boolean' ?
                            {
                                operator: FilterOperator.AND,
                                constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
                            } :
                            null
        })
        setFilters(_filters);
        setGlobalFilterValue('');
    }

    const filterTemplate = (type: string) => {
        switch (type) {
            case 'date':
                return dateFilterTemplate

            case 'numeric':
                return numericFilterTemplate

            default:
                return <></>
        }
    };

    const dateFilterTemplate = (options: any) => {
        return <Calendar showButtonBar value={options.value}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd.mm.yy" placeholder="dd.mm.yyyy" mask="99.99.9999"/>
    }

    const numericFilterTemplate = (options: any) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}/>
    }

    const columnSelection = () => {
        return (
            <React.Fragment>
                <i className={EButtonIcons.DOT_DOT_DOT_V} onClick={forHeaderSelection} style={{cursor: "pointer"}}/>
            </React.Fragment>
        );
    }

    // FIXME: Handle boolean values a bit better, this is a temporary fix
    const booleanBodyTemplate = (rowData: any) => {
        return <i style={{fontSize: '1.5rem'}} className={classNames('pi', {
            'true-icon pi-check-circle text-green-400': rowData.isActive,
            'false-icon pi-times-circle': !rowData.isActive
        })}></i>;
    };

    const bodyTemplate = (type: string) => {
        switch (type) {
            case 'boolean':
                return booleanBodyTemplate

            default:
                return <></>
        }
    };

    const rowClass = (data: any) => {
        if (data.isActive === false) {
            return {
                'bg-red-100': !data.isActive
            };
        } else {
            return {
                'bg-blue-100': data.hasActiveProject === false
            };
        }
    };


    function RowToggle(e: any) {
        const obj1 = e.data;
        if (expandedData === undefined || Object.keys(obj1).length === 0) {
            setExpandedData(e.data as DataTableExpandedRows);
            return;
        }

        const obj2 = expandedData;

        const intersection = Object.entries(obj1).reduce((result: any, [key, value]) => {
            if (obj2[key] === value) {
                result[key] = value;
            }
            return result;
        }, {});

        const complement = Object.entries({...obj1, ...obj2}).reduce((result: any, [key, value]) => {
            if (intersection[key] !== value) {
                result[key] = value;
            }
            return result;
        }, {});

        setExpandedData(complement as DataTableExpandedRows)
    }

    function ExpansionTemplate(e: any, handleDataFromChild: any) {
        setRowData(e);
        let params = {e, handleDataFromChild}
        if (props.options.expansionTemplate) {
            return (<>
                {props.options.expansionTemplate(params)}
            </>)
        }
        return (<></>)
    }

    function paginatorChangePage(event: any) {
        if (props.options.pagination) {
            setloading(true);

            setDataForPagination(event.rows, event.page);
        }
    }

    function paginatorChangePageFromTemplate3(event: any, isPrev: boolean) {
        if (props.options.pagination) {
            setloading(true);

            setDataForPagination(event.rows, pageNumber + (isPrev ? -1 : 1));
        }
    }

    function setDataForPagination(rows1: number, page: number) {
        if (rows1 === undefined) {
            rows1 = rowNumber;
        } else if (rows1 !== rowNumber) {
            setRowNumber(rows1);
        }
        if (page !== pageNumber) {
            setPageNumber(page);
        }
        if (props.options.pagination) {
            props.options.pagination.onChange(page, rows1)
                .then((dataFromBackend: any) => {
                    if (dataFromBackend && dataFromBackend.content) {
                        let ar: Array<Array<any>> = [];
                        ar.push(dataFromBackend.content);
                        setData(ar);
                    }
                })
                .finally(() => setloading(false))
        }
    }

    const paginationTemplate = {
        layout: ' PrevPageLink PageLinks NextPageLink RowsPerPageDropdown',
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                {label: 10, value: 10},
                {label: 50, value: 50},
                {label: 100, value: 100},
                {label: 500, value: 500},
                {label: 1000, value: 1000}
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>
                        Items per page:{' '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={(e) =>
                        setDataForPagination(e.value, pageNumber)}
                              className="w-7rem md:w-7rem"/>
                </React.Fragment>
            );
        },
        PrevPageLink: (options: any) => {
            let isDisabled = true;
            if (pageNumber > 0) {
                isDisabled = false;
            }
            return (
                <GenericButton onClick={(e: any) => paginatorChangePageFromTemplate3(e, true)}
                               icon={EButtonIcons.ANGLE_LEFT} color={EButtonColor.DARK_BLUE}
                               styled={EButtonStyle.TRANSPOSE} disabled={isDisabled} isRounded={true}/>
            );
        },
        NextPageLink: (options: any) => {
            let isDisabled = true;
            let ukupno = totalRecords ? totalRecords / rowNumber : 0;
            if (pageNumber + 1 < ukupno) {
                isDisabled = false;
            }
            return (
                <GenericButton onClick={(e: any) => paginatorChangePageFromTemplate3(e, false)}
                               icon={EButtonIcons.ANGLE_RIGHT} color={EButtonColor.DARK_BLUE}
                               styled={EButtonStyle.TRANSPOSE} disabled={isDisabled} isRounded={true}/>
            );
        },
        PageLinks: (options: any) => {
            let labela = (options.page + 1) as number;
            let stringLabela = labela.toString();

            return (
                <GenericButton onClick={options.onClick} label={stringLabela}
                               color={pageNumber === options.page ? EButtonColor.LUMINUM_BLUE : EButtonColor.DARK_BLUE}
                               styled={EButtonStyle.TRANSPOSE}
                               isRounded={true}
                />
            );
        },
    };

    const parseDate = (dateStr: string): Date => {
        const [day, month, year] = dateStr.split('.').map(Number);
        return new Date(year, month - 1, day);
    };

    const dateSortFunction = (event: { field: string, order: number | null | undefined, data: any[] }) => {

        event.data.sort((data1, data2) => {
            const date1 = parseDate(data1[event.field]);
            const date2 = parseDate(data2[event.field]);

            const order = event.order ?? 0; // Handle null or undefined
            return order * (date1.getTime() - date2.getTime());
        });
        return event.data
    };

    return (
        <div>
            <div className="card">
                <DataTable
                    height="100%"
                    value={_.orderBy(tableData, ['isActive', 'hasActiveProject', 'name', 'firstName'], ['desc', 'desc', 'asc', 'asc'])}
                    header={header}
                    responsiveLayout="scroll"
                    expandedRows={expandedData}
                    onRowToggle={(e) => RowToggle(e)}
                    rowExpansionTemplate={e => ExpansionTemplate(e, handleDataFromChild)}
                    removableSort
                    filters={filters}
                    filterDisplay="menu"
                    globalFilterFields={props.options.columns.fieldNames}
                    globalFilter={globalFilterValue}
                    dataKey="id"
                    onValueChange={(data) => {
                        if (props.options.timeSheet && props.options.timeSheet.isUkupno) {
                            setSumOfHours(data.map(x => x.numberOfHours).reduce((acc, hours) => acc + hours, 0));
                        }
                    }}
                    loading={loading}
                    selection={selectedData}
                    onSelectionChange={(e) => setSelectedData(e.value)}
                    emptyMessage="&nbsp;&nbsp;&nbsp;Nema pronađenih rezultata"
                    rowClassName={rowClass}>
                    <Column header="" style={{width: '1rem'}}/>
                    <Column header="#" headerStyle={{width: '3rem'}}
                            body={(data, options) => {
                                if (props.options.pagination?.hasPageable) {
                                    return options.rowIndex + 1 + pageNumber * rowNumber
                                } else {
                                    return options.rowIndex + 1
                                }
                            }}></Column>
                    {props.options.isDataMutable !== undefined && props.options.isDataMutable ?
                            <Column expander headerStyle={{width: '3rem'}}></Column>
                        :
                        <></>
                    }
                    {visibleColumns.map((head: any) => {
                        let i = 0;
                        // FIXME: Handle boolean values a bit better, this is a temporary fix
                        if (props.options.columns.headers.map((header: string, iteration: number) => header === head.header ? i = iteration : false)) {
                            if (props.options.columns.fieldTypes[i] === "boolean") {
                                return (
                                    <Column
                                        key={props.options.columns.fieldNames[i]}
                                        field={props.options.columns.fieldNames[i]}
                                        header={props.options.columns.headers[i]}
                                        sortable={props.options.columns.searchByFieldNames.includes(props.options.columns.fieldNames[i])}
                                        filterType={props.options.columns.fieldTypes[i]}
                                        filterPlaceholder={"Traži po " + props.options.columns.searchByFieldNames[i]}
                                        filterElement={filterTemplate(props.options.columns.fieldTypes[i])}
                                        dataType="boolean"
                                        body={bodyTemplate(props.options.columns.fieldTypes[i])}
                                    />)
                            } else if (props.options.columns.fieldTypes[i] === "date") {
                                return (
                                    <Column
                                        key={props.options.columns.fieldNames[i]}
                                        field={props.options.columns.fieldNames[i]}
                                        header={props.options.columns.headers[i]}
                                        sortable={props.options.columns.searchByFieldNames.includes(props.options.columns.fieldNames[i])}
                                        filterType={props.options.columns.fieldTypes[i]}
                                        filterPlaceholder={"Traži po " + props.options.columns.searchByFieldNames[i]}
                                        filterElement={filterTemplate(props.options.columns.fieldTypes[i])}
                                        dataType="date"
                                        sortFunction={dateSortFunction}
                                        alignHeader={"center"}
                                        bodyStyle={{ textAlign: 'left' }}
                                        className={"date-column"}
                                    />
                                )
                            } else if (props.options.columns.fieldTypes[i] === "numeric") {
                                return (
                                    <Column
                                        key={props.options.columns.fieldNames[i]}
                                        field={props.options.columns.fieldNames[i]}
                                        header={props.options.columns.headers[i]}
                                        sortable={props.options.columns.searchByFieldNames.includes(props.options.columns.fieldNames[i])}
                                        filterType={props.options.columns.fieldTypes[i]}
                                        filterPlaceholder={"Traži po " + props.options.columns.searchByFieldNames[i]}
                                        filterElement={filterTemplate(props.options.columns.fieldTypes[i])}
                                        dataType="numeric"
                                        alignHeader={"center"}
                                        bodyStyle={{ textAlign: 'right' }}
                                        className={"numeric-column"}
                                    />
                                )
                            } else {
                                return (
                                    <Column
                                        key={props.options.columns.fieldNames[i]}
                                        field={props.options.columns.fieldNames[i]}
                                        header={props.options.columns.headers[i]}
                                        sortable={props.options.columns.searchByFieldNames.includes(props.options.columns.fieldNames[i])}
                                        filterType={props.options.columns.fieldTypes[i]}
                                        filterPlaceholder={"Traži po " + props.options.columns.searchByFieldNames[i]}
                                        filterElement={filterTemplate(props.options.columns.fieldTypes[i])}
                                        alignHeader={"center"}
                                        className={"default-column"}
                                    />)
                            }
                        }
                    })}
                    <Column header={columnSelection} headerStyle={{width: '1rem', paddingRight: '1rem'}}></Column>
                </DataTable>
                {props.options.pagination?.hasPageable &&
                    <div style={{paddingTop: '1rem'}}>
                        <Paginator first={pageNumber} rows={rowNumber} totalRecords={totalRecords}
                                   rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                                   template={paginationTemplate}
                                   onPageChange={paginatorChangePage}></Paginator>
                    </div>
                }
            </div>

            {props.options.modalOptions.modals}
            {props.options.excelExportModalOption ?
                props.options.excelExportModalOption.modals
                :
                <></>
            }
            {pickTableHeaderModal()}

        </div>
    );
}

export default GenericTable;