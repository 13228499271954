import React, {useEffect, useState} from "react";
import {FormikErrors, useFormik} from "formik";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {classNames} from "primereact/utils";
import FormFooter from "../../../shared/components/generic/form-inside-table/FormFooter";
import Journal, {makeJournal, updateJournal} from "../../../models/Journal";
import {deleteEntity} from "../../../shared/components/generic/form-inside-table/GenericMethods";
import {edit} from "../../../shared/api/endpoints/CrudApi";
import {convertFromDate} from "../../../shared/utils/DateUtils";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import JournalType, {getAllJournalTypes} from "../../../models/JournalType";
import moment from "moment";

interface JournalsTemplateProps {
    entity: Journal;

    handleDataFromChild(e: any, action: string): void;
}

function JournalsTemplate(props: JournalsTemplateProps) {

    const [journal, setJournal] = useState<Journal>(
        makeJournal(props.entity ?? undefined)
    )
    const [journalTypes, setJournalTypes] = useState<JournalType[] | undefined>([]);

    useEffect(() => {
        (async () => {
            setJournalTypes(await getAllJournalTypes());
        })();
    }, [])

    const formik = useFormik({
        validateOnChange: true,
        initialValues: journal!,
        validate: (values: Journal) => {
            let errors: FormikErrors<Journal> = {};
            if (!values.name) {
                errors.name = 'Naziv je obavezno polje.';
            }
            if (values.validFromToShowOnForm?.toString() == 'Invalid Date' || !values.validFromToShowOnForm) {
                errors.validFromToShowOnForm = 'Vrijedi od je obavezan.'
            }
            if (!values.journalType) {
                errors.journalType = 'Tip narudžbenice je obavezan.'
            }

            if (values.journalType?.id !== 4 && moment(values.validFromToShowOnForm).isSameOrAfter(values.validToToShowOnForm)) {
                errors.validToToShowOnForm = 'Datum kraja mora biti veći od datuma početka.'
            }
            if (values.journalType?.id !== 4) {
                if (!values.scheduledHours) {
                    errors.scheduledHours = 'Za ovaj tip narudžbenice je potrebno unijeti sate.'
                }
                if (values.validToToShowOnForm?.toString() == 'Invalid Date' || !values.validToToShowOnForm) {
                    errors.validToToShowOnForm = 'Za ovaj tip narudžbenice je potrebno unijeti datum isteka.'
                }
            }

            return errors;
        },
        onSubmit:
            async (values: Journal) => {
                if (values.id) {
                    await edit('erv/admin/journal', values.id, updateJournal(values)).then(res => res ? props.handleDataFromChild(makeJournal(values), 'edit') : undefined);
                    ;
                }
            },
    });

    const formikTouched: any = formik.touched;
    const formikErrors: any = formik.errors;

    const isFormFieldValid = (name: string) => !!(formikTouched[name] && formikErrors[name]);
    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <small className="p-error">{formikErrors[name]}</small>;
    };

    async function deleteJournal() {
        await deleteEntity('erv/admin/journal', formik.values).then(res => res ? props.handleDataFromChild(makeJournal(formik.values), 'delete') : undefined);
    }

    const handleJournalTypes = (e: any) => {
        if (e.value.id === 4) {
            formik.setFieldValue('scheduledHours', undefined)
            formik.setFieldValue('validToToShowOnForm', null)
            formik.setFieldValue('validTo', null)
        } else {
            formik.setFieldValue('scheduledHours', formik.values.scheduledHours)
            formik.setFieldValue('validToToShowOnForm', formik.values.validToToShowOnForm)
        }
        formik.setFieldValue('journalType', e.value)

    }

    const handleValidFrom = (e: any) => {
        formik.setFieldValue('validFromToShowOnForm', e.value);
        formik.setFieldValue('validFrom', convertFromDate(e.value));
    }
    const handleValidTo = (e: any) => {
        formik.setFieldValue('validToToShowOnForm', e.value);
        formik.setFieldValue('validTo', convertFromDate(e.value));
    }

    return (
        <div className="expanded-div">
            <form>
                <div>
                    <div style={{border: "transparent"}}>
                        <div className="p-fluid grid" style={{paddingTop: '20px'}}>

                            <div className="field col-6">
                            <span className="p-float-label">
                                <InputText id="name" name="name"
                                           value={formik.values.name}
                                           onChange={formik.handleChange}/>
                                <label htmlFor="name"
                                       className={classNames({'p-error': isFormFieldValid('name')})}>Ime</label>
                            </span>
                                {getFormErrorMessage('name')}
                            </div>

                            <div className="field col-6">
                            <span className="p-float-label">
                                <InputNumber id="scheduledHours" name="scheduledHours"
                                             value={formik.values.scheduledHours}
                                             onChange={(e) => formik.setFieldValue("scheduledHours", e.value)}
                                             disabled={formik.values.journalType?.id === 4 ? true : false}
                                />
                                <label htmlFor="scheduledHours"
                                       className={classNames({'p-error': isFormFieldValid('scheduledHours')})}>Ugovoreni sati</label>
                            </span>
                                {getFormErrorMessage('scheduledHours')}
                            </div>

                            <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="startDateToShowOnForm"
                                          value={formik.values.validFromToShowOnForm}
                                          onChange={(e) => handleValidFrom(e)}
                                          dateFormat="dd.mm.yy." id="calendarFrom"
                                          disabledDays={[0, 6]}/>
                                <label htmlFor="calendarFrom"
                                       className={classNames({'p-error': isFormFieldValid('validFromToShowOnForm')})}>Vrijedi od</label>
                            </span>
                                {getFormErrorMessage('validFromToShowOnForm')}
                            </div>
                            <div className="field col-6 md:col-6">
                            <span className="p-float-label">
                                <Calendar showButtonBar name="endDateToShowOnForm"
                                          value={formik.values.validToToShowOnForm}
                                          onChange={(e) => handleValidTo(e)}
                                          dateFormat="dd.mm.yy." id="calendarTo"
                                          disabledDays={[0, 6]}
                                          disabled={formik.values.journalType?.id === 4 ? true : false}
                                />
                                <label htmlFor="calendarTo"
                                       className={classNames({'p-error': isFormFieldValid('validToToShowOnForm')})}>Vrijedo do</label>
                            </span>
                                {getFormErrorMessage('validToToShowOnForm')}
                            </div>
                            <div className="field col-6">
                            <span className="p-float-label">
                                <Dropdown id="name" filter
                                          dataKey="name"
                                          name="name"
                                          value={formik.values?.journalType}
                                          options={journalTypes}
                                          onChange={handleJournalTypes}
                                          optionLabel="name"
                                          placeholder="Odaberi tip narudžbenice"/>
                                <label htmlFor="name"
                                       className={classNames({'p-error': isFormFieldValid('journalType')})}>Tip narudžbenice</label>
                            </span>
                                {getFormErrorMessage('journalType')}
                            </div>
                        </div>
                    </div>
                    <FormFooter onClickUpdate={formik.handleSubmit} onClickDelete={() => deleteJournal()}/>
                </div>
            </form>
        </div>
    );
}

export default JournalsTemplate;