import Project from "./Project";
import Journal from "./Journal";

export default class JournalProjectTimeSheet {

    id?: number;
    journalId?: number;
    journalName?: string;
    projectId?: number;
    validFrom?: string;
    validTo?: string;
    isEditable?: boolean;


    constructor(id?: number, journalId?: number, journalName?: string, projectId?: number, validFrom?: string, validTo?: string, isEditable?: boolean) {
        this.id = id ?? undefined;
        this.journalId = journalId ?? undefined;
        this.journalName = journalName ?? '';
        this.projectId = projectId ?? undefined;
        this.validFrom = validFrom ?? '';
        this.validTo = validTo ?? '';
        this.isEditable = isEditable ?? false;
    }

}

export function makeJournalProjectTimeSheet(journalProjectTimeSheet: JournalProjectTimeSheet | undefined) {
    if (journalProjectTimeSheet === undefined) {
        return new JournalProjectTimeSheet();
    }
    return journalProjectTimeSheet;
}

export function makeJournalProjectTimesheetFromProjectAndJournal(project: Project | undefined, journal: Journal | undefined) {
    let journalProjectTimeSheet: JournalProjectTimeSheet = new JournalProjectTimeSheet(
        undefined,
        journal?.id,
        journal?.name,
        project?.id,
        journal?.validFrom,
        journal?.validTo,
        true
    );
    
    return journalProjectTimeSheet;
}
